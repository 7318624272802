<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <create-landing-page
      v-if="showCreate"
      @closed="showCreate = false"
      @success="createSuccess"
    />
    <banner-edit-landing-page
      v-if="showEdit"
      :bannerdetails="editBannerInfo"
      @get-banners="getBanners"
      @closed="showEdit = false"
    />
    <delete-banner
      v-if="showDelete"
      :title="'Delete Banner?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-text-multiple
      </v-icon> Banners List
    </h2>
    <br>
    <v-alert
      v-if="!permissionCheck('read-banner-list')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          v-if="permissionCheck('create-banner')"
          color="#FF3700"
          @click="newBanner()"
        >
          New Banner
        </v-btn>
      </v-col>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :items-per-page="bannerTable.itemsPerPage"
        :headers="bannerTable.headers"
        :items="banners"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template
          v-slot:item="{ item, expand, isExpanded }"
        >
          <tr
            class="clickable"
            @click="editBanner(item.id)"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.start | convertStringToLocalDatetime }}</td>
            <td>
              <font v-if="item.end">
                {{ item.end | convertStringToLocalDatetime }}
              </font>
              <font v-else>
                No End date
              </font>
            </td>
            <td>
              <font
                :color="getColor(item.active)"
                class="font-my-style"
              >
                {{ item.active | statusUpdate }}
              </font>
            </td>
            <td>
              {{ item.rotation_interval | rotationConverter }}
            </td>
            <td>
              {{ item.updated_at | diffHumans }}
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click.stop
                @click="deleteBanner(item.id, item.name, item.boards)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded)"
              >
                <v-icon
                  v-if="!isExpanded"
                  @click="getBoardDetails(item.boards)"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            v-if="permissionCheck('read-banner')"
            :colspan="headers.length"
          >
            <v-row>
              <v-col
                v-if="showAdvancedSchedule(item.schedules)"
                cols="12"
                lg="4"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Advanced Schedule</v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(schedule, index) in item.schedules">
                        <v-list-item :key="'item'+ index">
                          <template>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                <strong>
                                  Start: {{ schedule.start | twelveHour }} End: {{ schedule.end | twelveHour }}
                                </strong>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <strong>
                                  Active Days: {{ schedule.active_days | arrangePretty }}
                                </strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < item.schedules.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                lg="4"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>
                      File Preview
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="text-body-1 text-center">
                    <v-row>
                      <v-col
                        cols="2"
                        class="my-card-size"
                      >
                        <v-badge
                          right
                          overlap
                        >
                          <v-icon
                            slot="badge"
                            small
                            @click="editBanner(item.id)"
                          >
                            mdi-pencil-outline
                          </v-icon>
                          <div v-if="isContentImgOrGif(item.content.content_type)">
                            <v-img
                              width="100"
                              height="100"
                              class="my-media-file-style"
                              contain
                              :src="getFilePath(item.content.file_path)"
                              @click="editBanner(item.id)"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="#FF3700"
                                  />
                                </v-row>
                              </template>
                            </v-img>
                          </div>
                          <div v-else>
                            <video
                              width="100"
                              height="100"
                              class="my-media-file-style"
                              contain
                              controls
                            >
                              <source
                                :src="getFilePath(item.content.file_path)"
                                type="video/mp4"
                              >
                            </video>
                          </div>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                lg="4"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title>Device details</v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <template v-for="(board, index) in boardDetails">
                        <v-list-item :key="'item'+ index">
                          <template>
                            <v-list-item-content class="ml-1">
                              <v-list-item-title>
                                <strong>
                                  Device Id: {{ board.external_id }}
                                </strong>
                              </v-list-item-title>
                              <br>
                              <v-list-item-subtitle>
                                <strong>
                                  Device Location: {{ board.location }}
                                </strong>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider
                          v-if="index < boardDetails.length - 1"
                          :key="index"
                        />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <div class="py-3" />
      <v-snackbar
        v-model="sucAlert"
        color="success"
        icon="mdi-check-circle"
        transition="scale-transition"
        top
        right
      >
        {{ alertMessage }}
      </v-snackbar>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
  import CreateLandingPage from './CreateBannerLandingPage';
  import moment from 'moment';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';
  import { cdnUrl } from 'src/app-globals';
  import BannerEditLandingPage from './BannerEditLandingPage.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'Banners',

    components: {
      'create-landing-page': CreateLandingPage,
      'delete-banner': DeleteDialog,
      'centre-spinner': spinner,
      BannerEditLandingPage,
    },
    filters: {
      rotationConverter (val) {
        return val / 1000 + ' seconds ';
      },
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (active) {
        if (active === true) return 'Active*';
        else return 'Inactive';
      },
      arrangePretty (val) {
        return val.join(', ');
      },
      twelveHour (val) {
        return moment(val, 'HH:mm').format('hh:mm a');
      },
    },
    mixins: [global],

    data: () => ({
      alertMessage: '',
      bannerTable: {
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Start', value: 'start' },
          { text: 'End', value: 'end' },
          { text: 'Status', value: 'active' },
          { text: 'Rotation Interval', value: 'rotation_interval' },
          { text: 'Last Updated', value: 'updated_at' },
          { text: 'Edit', value: 'edit' },
          { text: 'Delete', value: 'delete' },
          { text: 'More', value: 'data-table-expand' },
        ],
        itemsPerPage: 10,
      },
      deleteId: '',
      helper: new ModuleHelper(),
      expanded: [],
      singleExpand: true,
      deleteName: '',
      editBannerInfo: {},
      loading: false,
      search: '',
      showCreate: false,
      showEdit: false,
      showDelete: false,
      sucAlert: false,
      boardExternalIds: [],
      boardDetails: [],
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true || vm.helper.isModuleExist(Constants.MODULE_BANNER) === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      banners () {
        const fetchedBanners = this.$store.getters['banners/getBannersList'];
        const filtredBanners = fetchedBanners.filter(i => i.campaign_linked === false);
        return filtredBanners;
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
      await this.getBanners();
      (this.permissionCheck('read-banner-list') && this.permissionCheck('read-banner')) && this.fetchDigiBoards();
    },
    methods: {
      showDevice () {
        this.showDigiBoards = true;
      },
      showAdvancedSchedule (schedule) {
        if (this.helper.isSubModuleExist(Constants.MODULE_BANNER, Constants.SUB_MODULE_ADVANCED_SCHEDULING) && schedule.length > 0) {
          return true;
        }
        return false;
      },
      editBanner (bannerId) {
        if (this.permissionCheck('edit-banner') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.editBannerInfo = this.banners.find(
          banner => banner.id === bannerId,
        );
        this.showEdit = true;
      },
      deleteBanner (bannerId, bannerName, boardExternalIds) {
        if (this.permissionCheck('delete-banner') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDelete = true;
        this.deleteId = bannerId;
        this.deleteName = bannerName;
        this.boardExternalIds = boardExternalIds;
      },
      deleteConfirmed () {
        this.loading = true;
        this.$store.dispatch('publish/addSelectedBoardsForPublish', this.boardExternalIds);
        this.$store.dispatch('banners/deleteBanner', this.deleteId)
          .then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Banner deleted.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.getBanners();
          }).finally(
            this.showDelete = false,
            this.deleteId = null,
            this.loading = false,
          );
      },
      async getBanners () {
        if (this.permissionCheck('read-banner-list') === false) {
          return;
        }
        this.loading = true;
        await this.$store.dispatch('banners/fetchBannersList');
        this.loading = false;
      },
      fetchDigiBoards () {
        this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      newBanner () {
        this.$router.push({ name: 'Create Banner' });
      },
      createSuccess () {
        this.getBanners();
        this.alertMessage = 'Banner created successfully.';
        this.sucAlert = true;
      },
      getColor (active) {
        if (active === true) return '#2E7D32';
        else return '#C62828';
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
     getBoardDetails (boardDetails) {
       if (this.permissionCheck('read-banner') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        const filteredBoards = this.digiBoards.filter(item => boardDetails.includes(item.external_id));
        this.boardDetails = filteredBoards;
      },
    },
  };
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.font-my-style {
  font-weight: bold;
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-media-file-style {
  background-color: rgb(236, 239, 241);
}
.my-card-size {
  height: 120px;
}
</style>
