<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    scrollable
  >
    <decision-page
      v-if="showDecisionPage"
      @cancel="showModal=false"
      @create="create()"
      @savedBanners="savedBanners()"
      @upload="upload()"
    />
    <v-slide-y-transition>
      <upload-content
        v-if="showUpload"
        @cancel="showModal=false"
        @errorthrown="handleError"
        @success="contentCreated"
      />
      <create-text
        v-if="showCreate"
        @cancel="showModal=false"
        @errorthrown="handleError"
        @success="contentCreated"
      />
      <create-banner
        v-if="showCreateBanner"
        :content="createdContent"
        @cancel="showModal=false"
        @success="bannerCreated"
      />
      <saved-banners
        v-if="showSavedBannersPage"
        @cancel="showModal=false"
        @success="contentCreated"
      />
    </v-slide-y-transition>
    <v-snackbar
      v-model="errorAlert"
      color="red"
      icon="mdi-close-circle-outline"
      transition="scale-transition"
      top
      right
    >
      {{ alertMessage }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import CreateText from './CreateTextContent';
  import DecisionPage from './CreateBannerDecision';
  import UploadContent from './UploadContent';
  import CreateBanner from './CreateBanner';
  import SavedBanners from './SavedBannersList';

  export default {
    name: 'CreateBannerLandingPage',
    components: {
      'decision-page': DecisionPage,
      'upload-content': UploadContent,
      'create-text': CreateText,
      'create-banner': CreateBanner,
      'saved-banners': SavedBanners,
    },
    data: () => ({
      alertMessage: '',
      errorAlert: false,
      showModal: true,
      showCreate: false,
      showCreateBanner: false,
      showUpload: false,
      showDecisionPage: true,
      showSavedBannersPage: false,
      createdContent: {},
    }),
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      bannerCreated () {
        this.showModal = false;
        this.showCreate = false;
        this.$emit('success');
      },
      create () {
        this.showDecisionPage = false;
        this.showCreate = true;
      },
      upload () {
        this.showDecisionPage = false;
        this.showUpload = true;
      },
      savedBanners () {
        this.showDecisionPage = false;
        this.showSavedBannersPage = true;
      },
      handleError (errorMsg) {
        this.alertMessage = errorMsg;
        this.errorAlert = true;
      },
      contentCreated (content) {
        this.createdContent = content;
        this.showCreate = false;
        this.showUpload = false;
        this.showSavedBannersPage = false;
        this.showCreateBanner = true;
      },
    },
  };
</script>
