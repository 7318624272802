<template>
  <v-card>
    <v-form>
      <v-card-title>
        <h4>Create Text Content</h4>
      </v-card-title>

      <v-card-text class="text-body-1 text-center">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="textInput"
                label="Text*"
                hint="Text to be displayed on the board."
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-subheader class="pl-0">
                Font Size
              </v-subheader>
              <v-slider
                v-model="fontSize"
                min="50"
                max="128"
                thumb-label
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-select
                v-model="xPosition"
                :items="possibleXPositions"
                label="xPosition"
                item-text="name"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-select
                v-model="yPosition"
                :items="possibleYPositions"
                label="yPosition"
                item-text="name"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                v-model="bgColor"
                :items="possibleBgColors"
                label="Background Color"
                item-text="name"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :color="textColor"
                    class="black--text"
                    dark
                    v-on="on"
                  >
                    Text Color
                  </v-btn>
                </template>
                <v-color-picker
                  v-model="textColor"
                  hide-canvas
                  hide-inputs
                  show-swatches
                  type="hexa"
                  class="mx-auto"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <p class="text-sm-left">
          *indicates required field
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mt-2"
          color="blue"
          depressed
          default
          rounded
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          depressed
          default
          rounded
          :disabled="!isFormValid"
          @click="submitText()"
        >
          Next
        </v-btn>
      </v-card-actions>
    </v-form>
    <centre-spinner
      :loading="loading"
      :loading-value="loadingValue"
      :message="loadingMessage"
    />
  </v-card>
  <!-- SHOW CONVERT TEXT ENDS HERE -->
</template>
<script>
  import Errors from 'src/errors';
  import spinner from 'src/views/dashboard/component/SpinnerWithValue';
  import axios from 'src/app-axios';

  export default {
    name: 'CreateTextContent',
    components: {
      'centre-spinner': spinner,
    },
    data: () => ({
      bgColor: 'black',
      contentCreated: {},
      fontSize: 50,
      loading: false,
      loadingValue: 0,
      loadingMessage: '',
      possibleBgColors: [
        { name: 'Black', value: 'black' },
        { name: 'Blue', value: 'blue' },
        { name: 'Green', value: 'green' },
        { name: 'Orange', value: 'orange' },
        { name: 'White', value: 'white' },
      ],
      possibleXPositions: [
        { name: 'Left', value: 'left' },
        { name: 'Middle', value: 'center' },
        { name: 'Right', value: 'right' },
      ],
      possibleYPositions: [
        { name: 'Top', value: 'top' },
        { name: 'Middle', value: 'middle' },
        { name: 'Bottom', value: 'bottom' },
      ],
      serverErrors: new Errors(),
      textInput: '',
      textMargin: 2,
      textColor: '#FFFFFFFF',
      xPosition: 'left',
      yPosition: 'top',
    }),
    computed: {
      isFormValid () {
        return this.textInput !== '';
      },
    },
    methods: {
      cancel () {
        this.$emit('cancel');
      },
      submitText () {
        this.loading = true;
        this.loadingMessage = 'Creating text message..';
        var config = {
          onUploadProgress: function (progressEvent) {
            this.loadingValue = (Math.round((progressEvent.loaded * 100) / progressEvent.total)) / 2;
          }.bind(this),
        };

        this.loadingMessage = 'Converting text message to file..';
        axios.post('api/banners/text/convert', {
          background_color: this.bgColor,
          font_size: this.fontSize,
          text: this.textInput,
          text_color: this.textColor,
          x_align: this.xPosition,
          y_align: this.yPosition,
        }, config).then(response => {
          this.loadingValue = 74;
          this.fileCreated = response.data.file_name;
          axios.post('api/banners/content/save', {
            content_file_name: this.fileCreated,
          })
            .then(response => {
              this.loadingValue = 100;
              this.contentCreated = response.data;
              this.$emit('success', this.contentCreated);
            })
            .catch(error => {
              this.loading = false;
              this.$emit('errorthrown', error.response.data.error);
            });
        })
          .catch(error => {
            this.loading = false;
            this.$emit('errorthrown', error.response.data.error);
          });
      },
    },
  };
</script>
