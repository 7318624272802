<template>
  <div>
    <!-- SHOW UPLOAD IMAGE STARTS HERE -->
    <v-card>
      <v-form v-model="isFormValid">
        <v-card-title>
          Upload Content
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="bannerFile"
                  label="Banner File*"
                  hint="Only jpeg, jpg, png files are allowed."
                  :rules="[fileRules.required, fileRules.size]"
                  :error-messages="serverErrors.get('content_file')"
                  @click:clear="clearFile()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="blue"
            depressed
            default
            rounded
            @click="cancel()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            depressed
            default
            rounded
            :disabled="!isFormValid"
            @click="uploadBanner()"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- SHOW UPLOAD IMAGE ENDS HERE -->

    <centre-spinner
      :loading="loading"
      :loading-value="loadingValue"
      :message="loadingMessage"
    />
  </div>
</template>
<script>
  import Errors from 'src/errors';
  import axios from 'src/app-axios';
  import spinner from 'src/views/dashboard/component/SpinnerWithValue';

  export default {
    name: 'UploadContent',
    components: {
      'centre-spinner': spinner,
    },
    data: () => ({
      bannerFile: [],
      fileRules: {
        size: value => value.size < 30000000 || 'File size should be less than 30 MB.',
        required: value => !!value || 'File Required.',
      },
      isFormValid: false,
      loadingMessage: '',
      loadingValue: 0,
      loading: false,
      showModal: true,
      serverErrors: new Errors(),
    }),
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      cancel () {
        this.$emit('cancel');
      },
      handleFileUpload () {
        this.bannerFile = this.$refs.bannerFile.files[0];
      },
      uploadBanner () {
        this.loading = true;
        this.loadingMessage = 'Uploading the banner..';
        const formData = new FormData();
        formData.append('content_file', this.bannerFile);
        formData.append('image_type', 'banner');
        var config = {
          onUploadProgress: function (progressEvent) {
            this.loadingValue = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        axios.post('api/banners/content/upload', formData, config)
          .then(response => {
            this.$emit('success', response.data);
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.$emit('errorthrown', error.response.data);
          });
      },
      clearFile () {
        this.bannerFile = [];
        this.serverErrors.clear('content_file');
      },
    },
  };
</script>
