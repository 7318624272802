<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
    scrollable
  >
    <edit-banner
      v-if="showBannerEdit"
      :editvalues="editBannerInfo"
      @closed="updationCancelled"
      @success="showDevice"
      @fetch-banners="getLatestBanners"
    />
    <edit-selected-digi-boards
      v-if="showDigiBoardEdit"
      :editvalues="editBannerInfo"
      :banner-form-details="bannerFormDetails"
      @update-success="updateSuccess"
      @update-failed="updationCancelled"
    />
  </v-dialog>
</template>
<script>
import EditBanner from './EditBanner';
import EditSelectedDigiBoards from './EditSelectedDigiBoards.vue';

export default {
    name: 'BannerEditLandingPage',
    components: {
        'edit-banner': EditBanner,
        EditSelectedDigiBoards,
    },
    props: {
      bannerdetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        showBannerEdit: true,
        showDigiBoardEdit: false,
        editBannerInfo: {},
        bannerFormDetails: {},
      };
    },
     watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    created () {
      this.editBannerInfo = this.bannerdetails;
    },
    methods: {
      showDevice (val) {
        this.bannerFormDetails = val;
        this.showBannerEdit = false;
        this.showDigiBoardEdit = true;
      },
      updateSuccess () {
        this.showModal = false;
        this.showBannerEdit = false;
        this.showDigiBoardEdit = false;
        this.$emit('get-banners');
      },
      updationCancelled () {
        this.showModal = false;
        this.showBannerEdit = false;
        this.showDigiBoardEdit = false;
      },
      getLatestBanners () {
        this.$emit('get-banners');
      },
    },
};
</script>
