<template>
  <v-card>
    <v-card-title style="background-color: #4CAF50; color: white">
      <h3>Do you like to select one from saved banners list?</h3>
    </v-card-title>
    <v-card-actions>
      <v-btn
        class="mt-2"
        color="blue"
        depressed
        default
        @click="cancel()"
      >
        CANCEL
      </v-btn>
      <v-spacer />
      <v-btn
        class="mt-2"
        color="green"
        depressed
        default
        @click="savedBanners()"
      >
        YES
      </v-btn>
      <v-btn
        class="mt-2"
        color="#FF3700"
        depressed
        default
        @click="uploadImage()"
      >
        UPLOAD
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'CreateBannerDecision',
    data: () => ({

    }),
    methods: {
      create () { // Text edit on fly is not using anymore.
        this.$emit('create');
      },
      savedBanners () {
        this.$emit('savedBanners');
      },
      uploadImage () {
        this.$emit('upload');
      },
      cancel () {
        this.$emit('cancel');
      },
    },
  };
</script>
