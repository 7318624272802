<template>
  <div>
    <delete-advanced-schedule
      v-if="showDeleteAdvancedSchedule"
      :title="'Delete this Advanced Schedule ?'"
      @closed="showDeleteAdvancedSchedule = false"
      @confirmed="deleteConfirm()"
    />
    <div v-if="showList">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          :cols="cardSizeChange"
        >
          <v-card
            v-for="(item, index) in advancedScheduleData"
            :key="'item'+ index"
            color="#b0bfb4"
            class="my-card-style"
          >
            <v-list
              class="my-list-style"
              two-line
            >
              <v-list-item-group>
                <template>
                  <v-list-item class="my-list-item-style">
                    <template>
                      <v-row v-if="!isMobile">
                        <v-col>
                          <v-list-item-content
                            class="ml-1"
                          >
                            <v-list-item-title>
                              <strong>
                                Start: {{ item.start | twelveHour }} End: {{ item.end | twelveHour }}
                              </strong>
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              <strong>
                                Days: {{ item.active_days | arrangePretty }}
                              </strong>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                        <v-col
                          class="my-btn-style"
                        >
                          <v-list-item-action>
                            <v-btn
                              elevation="1"
                              fab
                              x-small
                              icon
                              color="#37474F"
                              @click="editSchedule(item.id)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn
                              elevation="1"
                              fab
                              x-small
                              icon
                              color="#C62828"
                              @click="deleteSchedule(item.id, index)"
                            >
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                      <div v-if="isMobile">
                        <v-list-item-content
                          class="ml-1"
                        >
                          <font class="start-time-style mb-2">
                            Start: {{ item.start | twelveHour }} End: {{ item.end | twelveHour }}
                          </font>
                          <font class="days-list-style">
                            Days: {{ item.active_days | arrangePretty }}
                          </font>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            class="mt-n2"
                            elevation="1"
                            fab
                            x-small
                            icon
                            color="#37474F"
                            @click="editSchedule(item.id)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-btn
                            class="mt-n2"
                            elevation="1"
                            fab
                            x-small
                            icon
                            color="#C62828"
                            @click="deleteSchedule(item.id, index)"
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </div>
                    </template>
                  </v-list-item>
                  <v-divider
                    v-if="index < advancedScheduleData.length - 1"
                    :key="index"
                  />
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br>
  </div>
</template>

<script>
  import moment from 'moment';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import global from 'src/mixins/global';
  import Constants from 'src/constants';

  export default {
    name: 'AdvancedSchedulingListForEdit',
    components: {
      'delete-advanced-schedule': DeleteDialog,
    },
    filters: {
      arrangePretty (val) {
        return val.join(', ');
      },
      twelveHour (val) {
        return moment(val, 'HH:mm').format('hh:mm a');
      },
    },
    mixins: [global],
    data: () => ({
      headers: [
        {
          sortable: false,
          text: 'Start',
          value: 'start',
        },
        {
          sortable: false,
          text: 'End',
          value: 'end',
        },
        {
          sortable: false,
          text: 'Active Days',
          value: 'active_days',
        },
      ],
      showDeleteAdvancedSchedule: false,
      deleteId: '',
    }),
    computed: {
      showList () {
        const schedule = this.advancedScheduleData;
        return schedule.length > 0;
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getAdvancedScheduleForEdit'];
      },
      isMobile () {
        if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      cardSizeChange () {
        if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 10;
        } else {
          return 12;
        }
      },
    },
    methods: {
      editSchedule (scheduleId) {
        if (this.permissionCheck('edit-weekly-schedule') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.$emit('show-form', scheduleId);
      },
      deleteSchedule (scheduleId, index) {
        if (this.permissionCheck('delete-weekly-schedule') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showDeleteAdvancedSchedule = true;
        this.deleteId = scheduleId;
        this.deleteIndex = index;
      },
      deleteConfirm () {
        this.$store.dispatch('schedule/deleteExistingSchedule', {
          scheduleId: this.deleteId,
          deleteIndex: this.deleteIndex,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Advanced schedule deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('update-content');
        });
        this.showDeleteAdvancedSchedule = false;
      },
    },
  };
</script>
<style scoped>
.start-time-style {
  font-size: 14px;
}
.days-list-style {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.my-btn-style {
  text-align: right;
  margin-right: 10px;
}
.v-list-item__action:first-child {
  margin-right: 0px;
}
.my-card-style {
  margin-top: 10px;
  margin-bottom: 0px;
}
.my-list-item-style {
  padding-right: 0px;
}
.my-list-style {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
