<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="600"
    >
      <v-skeleton-loader
        v-if="showImageLoader"
        class="mx-auto"
        type="image"
      />
      <v-img
        v-if="imageReady"
        class="white--text"
        contain
        :src="createdFilePath"
        :aspect-ratio="aspectRatio"
      />
      <upload-content
        v-if="showUploadContent"
        @closed="showUploadContent = false"
        @click:outside="showUploadContent = false"
        @success="uploadSuccess"
      />
      <v-form v-model="isFormValid">
        <v-card>
          <v-card-title>
            Create Banner

            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="showModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="text-body-1 text-center">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Name*"
                    hint="Name for your reference. Only alpha dashes allowed."
                    required
                    :error-messages="serverErrors.get('name')"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Start date"
                  >
                    <v-datetime-picker
                      v-model="start"
                      label="Start Date time*"
                      date-format="yyyy-MM-dd"
                      time-format="HH:mm:ss"
                      :text-field-props="{
                        suffix: 'AEST',
                        errorMessages: errors,
                        class: 'custom-label-color',
                      }"
                      :date-picker-props="{
                        headerColor: '#FF3700',
                        min: minStartDate
                      }"
                      :time-picker-props="{
                        headerColor: '#FF3700'
                      }"
                    >
                      <template v-slot:dateIcon>
                        <v-icon
                          large
                        >
                          mdi-calendar
                        </v-icon>
                      </template>
                      <template v-slot:timeIcon>
                        <v-icon large>
                          mdi-alarm
                        </v-icon>
                      </template>
                    </v-datetime-picker>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-datetime-picker
                    v-model="end"
                    label="End Date time"
                    :text-field-props="{
                      hint: 'Leave blank if never ends',
                      persistentHint: true,
                      suffix: 'AEST'
                    }"
                    :date-picker-props="{
                      headerColor: '#FF3700',
                      min: minEndDate
                    }"
                    :time-picker-props="{
                      headerColor: '#FF3700'
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon large>
                        mdi-calendar
                      </v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon large>
                        mdi-alarm
                      </v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
                <v-col
                  cols="12"
                  class="text-left"
                >
                  <v-btn
                    v-if="showScheduleBtn"
                    small
                    dark
                    class="mt-2"
                    color="#FF3700"
                    depressed
                    default
                    rounded
                    @click="showAdvancedSchedule = true"
                  >
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                    Advanced Schedule
                  </v-btn>
                  <advanced-scheduling v-model="showAdvancedSchedule" />
                </v-col>
                <v-col
                  cols="12"
                >
                  <advanced-scheduling-list />
                </v-col>
                <br>
                <br>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-slider
                    v-model="rotationInterval"
                    max="30"
                    min="2"
                    label="Rotation Interval"
                    color="#FF3700"
                    thumb-label
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-row>
                    <v-checkbox
                      v-model="boardListEnable"
                      hide-details
                      class="shrink mr-2 mt-0"
                    />
                    <v-autocomplete
                      v-model="boardListed"
                      :disabled="boardListEnable"
                      :items="['BOARD-STBCB-001', 'BOARD-STBCB-002']"
                      label="All Boards"
                      multiple
                      chips
                      clearable
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <p class="text-sm-left">
              *indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              depressed
              default
              rounded
              @click="cancel()"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              depressed
              default
              rounded
              :disabled="!isFormValid"
              @click="submit()"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <delete-banner
        v-if="showDelete"
        :title="'Delete content?'"
        :message="'This action will remove the banner content. Are you sure?'"
        @closed="showDelete = false"
        @confirmed="deleteConfirmed()"
      />
    </v-dialog>
    <centre-spinner
      :loading="loading"
      :loading-value="loadingValue"
      :message="loadingMessage"
    />
  </div>
</template>

<script>

  import axios from 'src/app-axios';
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerWithValue';
  import Errors from 'src/errors';
  import UploadContent from './UploadContent';
  import { cdnUrl } from 'src/app-globals';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import AdvancedScheduling from '../component/schedules/AdvancedScheduling.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';
  import AdvancedSchedulingList from '../component/schedules/AdvancedSchedulingList.vue';

  export default {
    name: 'CreateBanner',
    components: {
      'centre-spinner': spinner,
      'delete-banner': DeleteDialog,
      'upload-content': UploadContent,
      AdvancedScheduling,
      AdvancedSchedulingList,
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      aspectRatio: 1.778, // 1920/1080
      contentCreated: {},
      createdFilePath: '',
      showModal: true,
      isFormValid: false,
      imageReady: false,
      showAdvancedSchedule: false,
      loadingTime: 3000,
      loadingValue: 54,
      loadingMessage: 'loading..',
      showImageLoader: true,
      name: '',
      start: '',
      end: null,
      boardListed: [],
      boardListEnable: true,
      helper: new ModuleHelper(),
      rotationInterval: 5,
      bannerFile: [],
      minStartDate: moment().format('YYYY-MM-DD'),
      loading: false,
      serverErrors: new Errors(),
      showDelete: false,
      deleteId: '',
      showUploadContent: false,
    }),
    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
      showScheduleBtn () {
        return this.helper.isSubModuleExist(Constants.MODULE_BANNER, Constants.SUB_MODULE_ADVANCED_SCHEDULING);
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
      start: function (val) {
        if (val !== '') this.serverErrors.clear('start');
      },
      name: function (val) {
        if (val !== '') this.serverErrors.clear('name');
      },
    },
    mounted () {
      this.startImage();
    },
    methods: {
      cancel () {
        this.showDelete = true;
      },
      bannerUpload () {
        this.showUploadContent = true;
      },
      convertToGmt (date) {
        return moment(date).utc();
      },
      deleteConfirmed () {
        this.$emit('cancel');
        this.$store.dispatch('schedule/clearAdvancedSchedule');
      },
      generateFormData () {
        const schedules = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          schedules.push(advancedSchedule.id);
        }

        return {
          unique_file_name: this.contentCreated.unique_file_name,
          board_listed: JSON.stringify(false),
          name: this.name,
          rotation_interval: this.rotationInterval * 1000,
          start: this.convertToGmt(this.start),
          end: this.end !== null ? this.convertToGmt(this.end) : null,
          schedules: schedules,
        };
      },
      submit () {
        this.loadingMessage = 'Creating and scheduling new banner..';
        this.loading = true;
        var formData = this.generateFormData();
        axios.post('api/banners', formData,
        ).then(response => {
          this.loading = false;
          this.showModal = false;
          this.$emit('success');
        })
          .catch(error => {
            this.loading = false;
            this.serverErrors.record(error.response.data.errors);
          });
        this.$store.dispatch('schedule/clearAdvancedSchedule');
      },
      clearFile () {
        this.bannerFile = [];
        this.serverErrors.clear('banner_file');
      },
      uploadSuccess (content) {
        this.showUploadContent = false;
        this.contentCreated = content;
        this.createdFilePath = `${cdnUrl}/${content.file_path}`;
        this.imageReady = true;
      },
      startImage () {
        this.contentCreated = this.content;
        this.createdFilePath = `${cdnUrl}/${this.content.file_path}`;
        setTimeout(() => {
          this.showImageLoader = false;
          this.imageReady = true;
        }, this.loadingTime);
      },
    },
  };
</script>
