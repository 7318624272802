<template>
  <v-overlay
    :absolute="absolute"
    :opacity="opacity"
    :value="loading"
    :z-index="zIndex"
  >
    <v-progress-circular
      v-if="loading"
      :rotate="360"
      :size="100"
      :width="15"
      :value="loadingValue"
      color="#FF3700"
    >
      {{ loadingValue }}
    </v-progress-circular>
    <p>{{ message }}</p>
  </v-overlay>
</template>
<script>
  export default {
    name: 'SpinnerCenter',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      loadingValue: {
        type: Number,
        default: 0,
      },
      message: {
        type: String,
        default: 'loading..',
      },
      spinnerColor: {
        type: String,
        default: 'primary',
      },
    },
    data: () => ({
      absolute: false,
      opacity: 0.46,
      zIndex: 5,
    }),
  };
</script>
